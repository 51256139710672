@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-turquoise: #34A2EA;
  --primary-dark: rgba(255,255,255, 0);
  --primary-dark-black: rgb(52, 58, 64);
  --primary-purple: #7817F9;
  --primary-hover-purple: #9b4fff;
  --primary-black: #000000;
  --primary-transparent-black: rgba(0,0,0,0.7);
  --primary-green: green;

}

body {
  font-family: "Roboto Condensed", sans-serif !important;
  overflow-x: hidden;
}

/*********************************** NAVBAR ***********************************/
nav {
  z-index: 2;
}

.logo {
  width: 2.5rem;
}

/************************************* MENU LINKS ******************************/

nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.45rem;
  margin-left: 0.45rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  margin-left:  1.125rem !important;
}

.navbar-nav > li > a:active, .navbar-nav > li > a:focus{
  border-top: 0.1875rem solid var(--primary-turquoise);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-turquoise);
}

/************************************* HEADER ***********************************/

.header-wraper {
  position: relative;
  background: url('https://anima-uploads.s3.amazonaws.com/projects/618c32d1838fa72543d00638/releases/618c32fb1a88ce4c088f5e9b/img/backgroundaboutme@1x.png')no-repeat;
  /*background: url('../src/images/wall-and-laptop-background.jpg') no-repeat;*/
  background-size: cover;
  background-position: center;
  height: 100vh;
  
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-white);
  text-transform: uppercase;
}


/************************************* TYPED TEXT ******************************/

.typed-text {
  font-size: 2rem;
  color: var(--primary-white);
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }

  .typed-text {
    font-size: 1.3rem !important;
  }
}

/************************** HEADER MAIN OFFER BUTTON **************************/

.btn-main-offer {
  border: 1px solid var(--primary-purple);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
  text-decoration: none;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-purple);
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/************************** PARTICLES **************************/

.tsparticles-canvas-el {
  position: absolute !important;
  height: 90vh !important;
  width: 100vw !important;
  z-index: 1 !important;
}

@media(max-width: 768px) {
  .tsparticles-canvas-el {
    display: none;
  }
}

/****************************** ABOUT ME ******************************/

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.profile-img {
  width: 20rem;
  border-radius: 50%;
  padding: 0.3rem;
  border: 1px solid var(--primary-turquoise);
}

.about-heading {
  color: var(--primary-turquoise);
  text-transform: uppercase;
}

@media(max-width:468px) {
  .profile-img {
    width: 10rem;
  }
  
}

/******************************** SERVICES ********************************/

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-turquoise);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-turquoise);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-turquoise);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-turquoise);
  border-bottom: 0.3125rem solid var(--primary-hover-purple);

}

.services .box:hover .icon {
  color: var(--primary-turquoise);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

.services .box:hover h3 {
  color: var(--primary-white);
}

/******************************** EXPERIENCE ********************************/

.experience {
  padding: 0.7rem;
}

.experience-wraper {
  position: relative;
  padding: 3.12rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-turquoise);
}

.experience-wraper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.31225 solid var(--primary-dark);
  background: var(--primary-turquoise);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-purple);
  font-weight: 900;
}

@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wraper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
  
}

/******************************** PORTFOLIO ********************************/


.portfolio-wraper {
  background: var(--primary-light-grey);
  padding: 3rem 0;
}

.portfolio-wraper h1 {
  color: var(--primary-turquoise);
}

.portfolio-image {
  width: 15rem !important;
  border: 1px solid var(--primary-turquoise);
  padding: 0.5rem;
  height: 8rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
  width: auto !important;
  padding: 0 !important;
}



/******************************** OVERFLOW ********************************/

.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-purple);
  cursor: pointer;

}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-turquoise);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;

}

/************************* OVERFLOW BOX | POPUPBOX *************************/

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-turquoise);
}

.hyper-link:hover {
  cursor: pointer;
  color: var(--primary-hover-purple);
}

.popupbox-overlay {
  background: none !important;
}

.popupbox-titleBar {
  color: var(--primary-turquoise);
}

.popupbox-wrapper {
  margin-top:  7rem;
}

/******************************** MEDIA ********************************/

@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
  .hyper-link {
    font-size: 100%;
  }
}

@media(max-height: 570px) {
.popupbox-wrapper {
  height: 50%;
}
  .portfolio-image-popupbox {
    width: 50%;
  }
  .hyper-link {
    font-size: 100%;
  }
}

@media(max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }
    .portfolio-image-popupbox {
      width: 30%;
    }
    .hyper-link {
      font-size: 100%;
    }
}

/****************************** TESTIMONIALS ******************************/

.testimonials {
  background-image: url(../src/images/testimonials-bg.jpg);
  background-size: cover;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}

.testimonials h1 {
  color: var(--primary-turquoise);
  padding: 3rem 0;
  text-transform: uppercase;
}

.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: var(--primary-transparent-black);
}

.myCarousel {
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.875rem;
}

.myCarousel h3 {
  color: var(--primary-turquoise);
  font-weight: 600;
  text-transform: uppercase;
}

.myCarousel p {
  color: var(--primary-grey);
}

.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}

/****************************** CONTACTS ******************************/


.contacts {
  background: var(--primary-dark-black);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-turquoise);
  padding: 1.3rem 0;
  text-transform: uppercase;
}
.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/************************ CONTACTS INPUTS *************************/

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-grey);
  outline: 0 !important;
  box-shadow: none !important;
  top: 0;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: var(--primary-turquoise);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-turquoise);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

input:-webkit-autofill {
  background: none;
}

input:-webkit-autofill:focus {
  background: none;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-purple);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

/***************** MEDIA CONTACTS INPUTS ******************/

@media (max-width: 768px) {
  .contacts {
    overflow: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 0.4rem;
  }

}

/************************ Footer *************************/

.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-turquoise);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}